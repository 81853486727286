export default [
  {
    header: "Инвестиции",
    icon: "BriefcaseIcon",
    children: [
      {
        title: "Обзор",
        route: "inv-overview",
        icon: "EyeIcon",
      },
      {
        title: "Аналитика",
        route: "inv-analytics",
        icon: "PieChartIcon",
        disabled: true,
      },
      {
        title: "Сделки",
        route: "inv-transactions",
        icon: "AlignJustifyIcon",
      },
      {
        title: "Ребалансировка",
        route: "inv-rebalancing",
        icon: "ShuffleIcon",
        disabled: true,
      },
      {
        title: "Портфели",
        route: "inv-portfolios",
        icon: "BriefcaseIcon",
        disabled: true,
      },
      {
        title: "Ценные бумаги",
        route: "inv-securities",
        icon: "LayersIcon",
      },
    ],
  },
];
